import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationPin, faPhone } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

const Contact = React.forwardRef((props, ref) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        emailjs.init("gWm1yzhpv2PkRMrty");
    }, []);

    const submit = (e) => {
        e.preventDefault();

        emailjs.send("service_bhvx7f4","template_fpsyvwv", {
            user_name: username,
            user_email: email,
            message: message
        });

        setEmailSent(true);
    }

    const usernameHandler = (e) => setUsername(e.target.value);
    const emailHandler = (e) => setEmail(e.target.value);
    const messageHandler = (e) => setMessage(e.target.value);

    return (
        <section className="Contact">
           <div ref={ref} style={{ position: 'absolute', top: "50px" }}></div>
           <h2>Kontakt</h2>

           <div className="container">
                <form className="form-container" onSubmit={ submit }>
                    <input type="text" placeholder='Imię' onChange={ usernameHandler } />
                    <input type="email" placeholder='Email' onChange={ emailHandler } />
                    <textarea rows="8" placeholder='Wiadomość' onChange={ messageHandler } />
                    <input type="submit" disabled={ emailSent } value={ emailSent ? 'Wysłano' : 'Wyślij' } style={{ color: emailSent ? 'green' : '#aaa' }} />
                </form>
                <article className="info-container" style={{ backgroundImage: 'url(/images/contact/1.webp)'}}>
                    <div>
                        <FontAwesomeIcon icon={faPhone} fontSize="24px" />
                        <span><b>Numer telefonu:</b> +48 512 010 003</span>
                    </div>
                    
                    <div>
                        <FontAwesomeIcon icon={faEnvelope} fontSize="24px" />
                        <span><b>Adres email:</b> biuro@ekobarn.pl</span>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faLocationPin} fontSize="24px" />
                        <span><b>Zakład produkcji Ekobarn:</b> 87-103 Mała Nieszawka ul. Wodociągowa 34/36</span>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faLocationPin} fontSize="24px" />
                        <span><b>Siedziba:</b> Toruń ul. Włocławska 231/4</span>
                    </div>
                </article>
           </div>
        </section>
    );
})

export default Contact;