import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";

import App from './App';
import Details from './components/Details';

import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={ <App /> } />
      <Route path="/details/:id" element={ <Details /> } />
    </Routes>
  </HashRouter>
);