import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

import Footer from '../Footer';
import Contact from '../Contact';

import './index.scss';

const Details = () => {
    let params = useParams();
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);

   const goToHomepage = () => window.location.href = `/`;

    const toggleMobileMenu = (event) => {
        setShowMobileMenu(!showMobileMenu);
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <header className="Header">
                <div className="logo"><a href="/">EkoBarn</a></div>

                <nav className="navigation">
                    <ul className="desktop-menu noselect">
                        <li onClick={goToHomepage}>Strona główna</li>
                    </ul>

                    <div className="mobile-menu-icon" onClick={toggleMobileMenu} style={{ color: '#aaa' }}>
                        { showMobileMenu ? <FontAwesomeIcon icon={faXmark} size="xl" /> : <FontAwesomeIcon icon={faEllipsisVertical} size="xl" /> }
                    </div>
                </nav>
                <div>
                    <div className={`mobile-menu ${ showMobileMenu ? '' : 'mobile-menu-hidden' }`}>
                        <ul>
                            <li onClick={goToHomepage}>Strona główna</li>
                        </ul>
                    </div>
                </div>
            </header>

            <section className="Details">
                <div className="container">
                    <section className="main">
                        <article className="image">
                            <img src={`/images/details/${params.id}/1.webp`} alt="" />
                        </article>
                        <article className="text">
                            { params.id === '1' ? <h2>Dom całoroczny piętrowy, cena brutto: 269.000zł</h2> : <></> }
                            { params.id === '2' ? <h2>Dom parterowy ocieplany do 35 m², cena brutto 89 000 zł</h2> : <></> }
                            { params.id === '3' ? <h2>Dom parterowy ocieplany z fasadą do 35 m², cena brutto 118 000 zł</h2> : <></> }
                            { params.id === '4' ? <h2>Dom parterowy ocieplany do 35 m² z tarasem 16,5 m² cena brutto 118 000 zł</h2> : <></> }
                            { params.id === '5' ? <h2>Dom ocieplany piętrowy do 35 m² + antresola 16,5 m², cena brutto 98 000 zł</h2> : <></> }
                            { params.id === '6' ? <h2>Dom ocieplany piętrowy z przeszkleniami górnymi do 35 m² + antresola 16,5 m², cena brutto 126 000 zł</h2> : <></> }
                            { params.id === '7' ? <h2>Dom całoroczny z antresolą, cena brutto: 238.000zł</h2> : <></> }
                            { params.id === '8' ? 
                                <div style={{ textAlign: 'center' }}>
                                    <h2 style={{ marginBottom: '0px' }}>Ogród zimowy</h2><br />
                                    <h4 style={{ marginTop: '-5px', color: '#444', fontWeight: 'normal' }}>Sala bankietowa, sala restauracyjna</h4>
                                </div> 
                                : <></> 
                            }

                            { params.id === '1' ? <span className="first">Dom o konstrukcji szkieletowej budowany w technologii pasywnej o grubości ścian
29 cm charakteryzujący się wysoką energooszczędnością, na pozwolenie lub
zgłoszenie. Domek został zaprojektowany wraz z instalacjami branżowymi, posiada
łazienki i salon główny z aneksem kuchennym, pomieszczeniem gospodarczym i
trzema sypialniami. Domek wyposażony w wysokiej klasy stolarkę PCV pod kolor
elewacji i zewnętrznymi drzwiami pod kolor elewacji, wentylację z odzyskiem ciepła ,
instalacje grzewczą . Czas budowy z pełnym wykończeniem wynosi około 3
miesięcy.</span> : <></> }
                            { params.id === '2' ? <span className="first">Dom charakteryzujący się przestronnym wnętrzem na zgłoszenie. Domek został zaprojektowany wraz z instalacjami branżowymi, posiada łazienkę i salon główny z aneksem kuchennym z możliwością dobudowania sypialni. Domek wyposażony w wysokiej klasy stolarkę PCV pod kolor elewacji i zewnętrznymi drzwiami pod kolor elewacji. Czas montażu z pełnym wykończeniem wynosi około miesiąca.</span> : <></> }
                            { params.id === '3' ? <span className="first">Dom charakteryzujący się przestronnym wnętrzem na zgłoszenie. Domek został zaprojektowany wraz z instalacjami branżowymi, posiada łazienkę i salon główny z aneksem kuchennym z możliwością dobudowania sypialni. Domek wyposażony w wysokiej klasy stolarkę PCV pod kolor elewacji i zewnętrznymi drzwiami pod kolor elewacji. Czas montażu z pełnym wykończeniem wynosi około miesiąca.</span> : <></> }
                            { params.id === '4' ? <span className="first">Dom charakteryzujący się przestronnym wnętrzem na zgłoszenie. Domek został zaprojektowany wraz z instalacjami branżowymi, posiada łazienkę i salon główny z aneksem kuchennym z możliwością dobudowania sypialni. Domek wyposażony w wysokiej klasy stolarkę PCV pod kolor elewacji i zewnętrznymi drzwiami pod kolor elewacji. Czas montażu z pełnym wykończeniem wynosi około miesiąca.</span> : <></> }
                            { params.id === '5' ? <span className="first">Dom charakteryzujący się przestronnym wnętrzem na zgłoszenie. Domek został zaprojektowany wraz z instalacjami branżowymi, posiada łazienkę i salon główny z aneksem kuchennym z możliwością dobudowania sypialni. Domek wyposażony w wysokiej klasy stolarkę PCV pod kolor elewacji i zewnętrznymi drzwiami pod kolor elewacji. Czas montażu z pełnym wykończeniem wynosi około miesiąca.</span> : <></> }
                            { params.id === '6' ? <span className="first">Dom charakteryzujący się przestronnym wnętrzem na zgłoszenie. Domek został zaprojektowany wraz z instalacjami branżowymi, posiada łazienkę i salon główny z aneksem kuchennym z możliwością dobudowania sypialni. Domek wyposażony w wysokiej klasy stolarkę PCV pod kolor elewacji i zewnętrznymi drzwiami pod kolor elewacji. Czas montażu z pełnym wykończeniem wynosi około miesiąca.</span> : <></> }
                            { params.id === '7' ? <span className="first">Dom o konstrukcji szkieletowej budowany w technologii pasywnej o grubości ścian
35 cm charakteryzujący się wysoką energooszczędnością, na zgłoszenie. Domek
został zaprojektowany wraz z instalacjami branżowymi, posiada łazienkę i salon
główny z aneksem kuchennym i trzema sypialniami. Domek wyposażony w wysokiej
klasy stolarkę PCV pod kolor elewacji i zewnętrznymi drzwiami pod kolor elewacji.
Czas budowy z pełnym wykończeniem wynosi około 3 miesięcy.</span> : <></> }
                            
                            
                            { params.id === '1' 
                            ?  <>
                            <div>
                                <img src={`/images/details/${params.id}/tabelka.webp`} alt="" />
                            </div>
                            </>
                            : <></> }

                            { params.id === '2' 
                            ?  <>
                            <br /><br /><b>Elementy domu:</b><br />
                            <div>
                                <p>- komplet konstrukcji szkieletowej,</p>
                                <p>- zestaw elewacji drewnianej,</p>
                                <p>- panele elewacyjne i dachowe z blachy powlekanej w rąbek stające wraz z konstrukcyjna deską elewacyjną,</p>
                                <p>- stolarka otworowa pod kolor elewacji (2 sztuki 90x210 UR, 2 sztuki 90x210 FIX, 1 sztuka 50x50 R, drzwi zewnętrzne kolor grafit 2 zamki)</p>
                                <p>- materiały i akcesoria (membrana wiatroizolacyjna, folia paroizolacyjna, taśma kalenicowa, wełna izolacyjna 150mm, śruby, wkręty itp.)</p>
                                <p>- gotowe elementy do obróbek blacharskich (gąsiory, maskownice, rynny (opcjonalnie), zetki, listwy wykańczające itp.)</p>
                                <p>- impregnacja i malowanie pod kolor elementów drewnianych </p>
                                <p>- deska tarasowa ryflowana</p>
                            </div>
                            </>
                            : <></> }
                            
                            { params.id === '3' 
                                ?  <>
                                <br /><br /><b>Elementy domu:</b><br />
                                <div>
                                    <p>- komplet konstrukcji szkieletowej,</p>
                                    <p>- zestaw elewacji drewnianej,</p>
                                    <p>- panele elewacyjne i dachowe z blachy powlekanej w rąbek stające wraz z konstrukcyjna deską elewacyjną,</p>
                                    <p>- stolarka otworowa pod kolor elewacji (1 sztuka 90x210 UR, fasada aluminiowa z drzwiami  160x210 UR  ze słupkiem ruchomym, 1 sztuka 50x50 R, drzwi zewnętrzne kolor grafit 2 zamki)</p>
                                    <p>- materiały i akcesoria (membrana wiatroizolacyjna, folia paroizolacyjna, taśma kalenicowa, wełna izolacyjna 150mm, śruby, wkręty itp.)</p>
                                    <p>- gotowe elementy do obróbek blacharskich (gąsiory, maskownice, rynny (opcjonalnie), zetki, listwy wykańczające itp.)</p>
                                    <p>- impregnacja i malowanie pod kolor elementów drewnianych </p>
                                    <p>- deska tarasowa ryflowana</p>
                                </div>
                                </>
                                : <></> 
                            }
                            
                            { params.id === '4' 
                                ?  <>
                                <br /><br /><b>Elementy domu:</b><br />
                                <div>
                                    <p>- komplet konstrukcji szkieletowej,</p>
                                    <p>- zestaw elewacji drewnianej,</p>
                                    <p>- panele elewacyjne i dachowe z blachy powlekanej w rąbek stające wraz z konstrukcyjna deską elewacyjną,</p>
                                    <p>- stolarka otworowa pod kolor elewacji (2 sztuki 90x210 UR, 2 sztuki 90x210 FIX, 1 sztuka 50x50 R, drzwi zewnętrzne kolor grafit 2 zamki)</p>
                                    <p>- materiały i akcesoria (membrana wiatroizolacyjna, folia paroizolacyjna, taśma kalenicowa, wełna izolacyjna 150mm, śruby, wkręty itp.)</p>
                                    <p>- gotowe elementy do obróbek blacharskich (gąsiory, maskownice, rynny (opcjonalnie), zetki, listwy wykańczające itp.)</p>
                                    <p>- impregnacja i malowanie pod kolor elementów drewnianych </p>
                                    <p>- deska tarasowa ryflowana</p>
                                </div>
                                </>
                                : <></> 
                            }

                            { params.id === '5' 
                                ?  <>
                                <br /><br /><b>Elementy domu:</b><br />
                                <div>
                                    <p>- komplet konstrukcji szkieletowej,</p>
                                    <p>- konstrukcja antresoli ze schodami,</p>
                                    <p>- zestaw elewacji drewnianej,</p>
                                    <p>- panele elewacyjne i dachowe z blachy powlekanej w rąbek stające wraz z konstrukcyjna deską elewacyjną,</p>
                                    <p>- stolarka otworowa pod kolor elewacji (2 sztuki 90x50 UR, 2 sztuki 90x210 FIX, 1 sztuka 90x210 UR, 1 sztuka 50x50 UR)</p>
                                    <p>- materiały i akcesoria (membrana wiatroizolacyjna, folia paroizolacyjna, taśma kalenicowa, wełna izolacyjna 150mm, śruby, wkręty itp.)</p>
                                    <p>- gotowe elementy do obróbek blacharskich (gąsiory, maskownice, rynny (opcjonalnie), zetki, listwy wykańczające itp.)</p>
                                    <p>- impregnacja i malowanie pod kolor elementów drewnianych </p>
                                    <p>- deska tarasowa ryflowana</p>
                                </div>
                                </>
                                : <></> 
                            }

                            { params.id === '6' 
                                ?  <>
                                <br /><br /><b>Elementy domu:</b><br />
                                <div>
                                    <p>- komplet konstrukcji szkieletowej,</p>
                                    <p>- konstrukcja antresoli ze schodami,</p>
                                    <p>- zestaw elewacji drewnianej,</p>
                                    <p>- panele elewacyjne i dachowe z blachy powlekanej w rąbek stające wraz z konstrukcyjna deską elewacyjną,</p>
                                    <p>- stolarka otworowa pod kolor elewacji (4 sztuki okien frontowych skośnych FIX,  2 sztuki 90x260 URR, 2 sztuki 90x210 FIX,2 sztuki 90x210UR, 1 sztuka 50x50 R, drzwi zewnętrzne kolor grafit 2 zamki)</p>
                                    <p>- materiały i akcesoria (membrana wiatroizolacyjna, folia paroizolacyjna, taśma kalenicowa, wełna izolacyjna 150mm, śruby, wkręty itp.)</p>
                                    <p>- gotowe elementy do obróbek blacharskich (gąsiory, maskownice, rynny (opcjonalnie), zetki, listwy wykańczające itp.)</p>
                                    <p>- impregnacja i malowanie pod kolor elementów drewnianych </p>
                                    <p>- deska tarasowa ryflowana</p>
                                </div>
                                </>
                                : <></> 
                            }

                            { params.id === '7' 
                                ?  <>
                                <div className='tabelki'>
                                    <img src={`/images/details/${params.id}/tabelka.webp`} alt="" />
                                    <img src={`/images/details/${params.id}/zestawienie.webp`} alt="" />
                                </div>
                                </>
                                : <></> }

                        </article>
                    </section>

                    <section className="main">
                        <article className="image">
                            { params.id === '1' 
                                ? <>
                                    <div>
                                        <p><b>Powierzchnia:</b> 98.64m2</p>
                                        <p><b>Wymiary płyty fundamentowej:</b> 650x1000</p>
                                    </div>

                                    <p style={{marginLeft: '40px'}}><b>Media:</b></p>
                                    <div style={{marginLeft: '20px'}}>
                                        <p>- wentylacja odzyskiem ciepła (opcjonalnie)</p>
                                        <p>- wentylacja grawitacyjna w pomieszczeniach socjalnych</p>
                                        <p>- instalacja elektryczna</p>
                                        <p>- instalacja wodna ciepłej i zimnej wody z przyłączeniami bez armatury i białego montażu</p>
                                        <p>- instalacja kanalizacyjna z odpływami.</p>
                                        <p>- instalacja grzewcza (opcjonalnie)</p>
                                    </div>
                                </>
                                : <></> 
                            }

                            { params.id === '2' 
                                ? <div>
                                    <p><b>Wymiar domku:</b> 570cm x 600cm (mierzone po obrysie jego podstawy z podcieniem)</p>
                                    <p><b>Wysokość całkowita:</b> 330 cm</p>
                                    <p><b>Wysokość ścian:</b> 240 cm</p>
                                    <p><b>Parter - pomieszczenia:</b> łazienka, salon z aneksem, sypialnia(opcjonalnie), taras</p>
                                    <p><b>Stan pod klucz:</b></p>
                                    <p><b>Instalacja wewnętrzna elektryczna:</b> 4 punkty świetlne, 4 gniazdka i 4 włączniki i wod-kan.</p>
                                    <p><b>Deska podlogowa:</b> na pióro-wpust , styropian izolujący 50mm.</p>
                                    <p><b>Wewnętrzne pokrycie ścian i sufitu:</b> boazeria drewniana; ściany łazienki: płyta gk</p>
                                </div>
                                : <></> 
                            }

                            { params.id === '3' 
                                ? <div>
                                    <p><b>Wymiar domku:</b> 570cm x 600cm (mierzone po obrysie jego podstawy z podcieniem)</p>
                                    <p><b>Wysokość całkowita:</b> 330 cm</p>
                                    <p><b>Wysokość ścian:</b> 240 cm</p>
                                    <p><b>Parter - pomieszczenia:</b> łazienka, salon z aneksem, sypialnia(opcjonalnie), taras</p>
                                    <p><b>Stan pod klucz:</b></p>
                                    <p><b>Instalacja wewnętrzna elektryczna:</b> 4 punkty świetlne, 4 gniazdka i 4 włączniki i wod-kan.</p>
                                    <p><b>Deska podlogowa:</b> na pióro-wpust</p>
                                    <p><b>Wewnętrzne pokrycie ścian i sufitu:</b> boazeria drewniana; ściany łazienki: płyta gk</p>
                                </div>
                                : <></> 
                            }

                            { params.id === '4' 
                                ? <div>
                                    <p><b>Wymiar domku:</b> 570cm x 600cm (mierzone po obrysie jego podstawy z podcieniem)</p>
                                    <p><b>Wysokość całkowita:</b> 330 cm</p>
                                    <p><b>Wysokość ścian:</b> 240 cm</p>
                                    <p><b>Parter - pomieszczenia:</b> łazienka, salon z aneksem, sypialnia(opcjonalnie), taras</p>
                                    <p><b>Stan pod klucz:</b></p>
                                    <p><b>Instalacja wewnętrzna elektryczna:</b> 4 punkty świetlne, 4 gniazdka i 4 włączniki i wod-kan.</p>
                                    <p><b>Deska podlogowa:</b> na pióro-wpust</p>
                                    <p><b>Wewnętrzne pokrycie ścian i sufitu:</b> boazeria drewniana; ściany łazienki: płyta gk</p>
                                </div>
                                : <></> 
                            }

                            { params.id === '5' 
                                ? <div>
                                    <p><b>Wymiar domku:</b> 570cm x 600cm (mierzone po obrysie jego podstawy z podcieniem)</p>
                                    <p><b>Wysokość całkowita:</b> 455 cm</p>
                                    <p><b>Wysokość ścian:</b> 360 cm, wysokość ścianki kalenicowej: 110 cm</p>
                                    <p><b>Parter - pomieszczenia:</b> łazienka, salon z aneksem, sypialnia(opcjonalnie), taras</p>
                                    <p><b>Stan pod klucz:</b></p>
                                    <p><b>Instalacja wewnętrzna elektryczna:</b> 4 punkty świetlne, 4 gniazdka i 4 włączniki i wod-kan</p>
                                    <p><b>Deska podłogowa na parterze, antresoli i tarasie :</b> na pióro-wpust (lub posadzka)</p>
                                    <p><b>Wewnętrzne pokrycie ścian i sufitu:</b> boazeria drewniana; ściany łazienki: płyta gk</p>
                                </div>
                                : <></> 
                            }

                            { params.id === '6' 
                                ? <div>
                                    <p><b>Wymiar domku:</b> 570cm x 600cm (mierzone po obrysie jego podstawy z podcieniem)</p>
                                    <p><b>Wysokość całkowita:</b> 455 cm</p>
                                    <p><b>Wysokość ścian:</b> 360 cm, wysokość ścianki kalenicowej: 110 cm</p>
                                    <p><b>Parter - pomieszczenia:</b> łazienka, salon z aneksem, sypialnia(opcjonalnie), taras</p>
                                    <p><b>Stan pod klucz:</b></p>
                                    <p><b>Instalacja wewnętrzna elektryczna:</b> 5 punktów świetlnych, 5 gniazdek i 5 włączników i wod-kan</p>
                                    <p><b>Deska podłogowa na parterze, antresoli i tarasie :</b> na pióro-wpust</p>
                                    <p><b>Wewnętrzne pokrycie ścian i sufitu:</b> boazeria drewniana; ściany łazienki: płyta gk</p>
                                </div>
                                : <></> 
                            }

                            { params.id === '7' 
                                ? <>
                                    <div>
                                        <p><b>Powierzchnia zabudowy:</b> 76.82m2</p>
                                        <p><b>Wymiary płyty fundamentowej:</b> 650x800</p>
                                    </div>

                                    <p style={{marginLeft: '40px'}}><b>Media:</b></p>
                                    <div style={{marginLeft: '20px'}}>
                                        <p>- wentylacja odzyskiem ciepła (opcjonalnie)</p>
                                        <p>- wentylacja grawitacyjna w pomieszczeniach socjalnych</p>
                                        <p>- instalacja elektryczna</p>
                                        <p>- instalacja wodna ciepłej i zimnej wody z przyłączeniami bez armatury i białego montażu</p>
                                        <p>- instalacja kanalizacyjna z odpływami.</p>
                                        <p>- instalacja grzewcza (opcjonalnie)</p>
                                    </div>

                                    <p style={{ marginTop: '20px' }}><b>Strop antresola:</b></p>
                                    <div>
                                        <p>- belki wsporcze 60x220mm z drewna konstrukcyjnego</p>
                                        <p>- deska lita dwustronnie szlifowana na pióro wpust</p>
                                        <p>- schody szkielet stalowy czarny mat, stopnie lite drewno</p>
                                    </div>
                                </>
                                : <></> 
                            }
                            
                        </article>

                        { params.id !== '8' 
                            ?
                            <article className="text">
                                <p style={{ marginTop: '20px' }}><b>Dokumentacja:</b></p>

                                <div>
                                    <p>- ogólny projekt budowlany,</p>
                                    <p>- branżowy projekt instalacji wod-kan.</p>
                                    <p>- branżowy projekt instalacji elektrycznej,</p>
                                </div>                            

                            </article>  
                            : <></>
                        }
                    </section>

                    { params.id === '1'
                        ? <h2 className="info">Wykonanie płyty fundamentowej o wymiarach 650x800, wraz z pracami ziemnymi i wymianą gruntu (do 40cm)</h2> 
                        : <></>
                    }

                    { params.id !== '1' && params.id !== '8'
                        ? <h2 className="info">Wykonanie płyty fundamentowej o wymiarach 570x600, wraz z pracami ziemnymi i wymianą gruntu (do 40cm), cena brutto 8500 zł.</h2>
                        : <></>
                    }

                    <section className="gallery">
                        { params.id === '1' || params.id === '7'
                            ? <>
                                <article className="image-one">
                                    <img src={`/images/details/${params.id}/2.webp`} alt="" />
                                </article>

                                <article className="image-one">
                                    <img src={`/images/details/${params.id}/3.webp`} alt="" />
                                </article>
                            </>
                            : <>
                                <article className="image">
                                    <img src={`/images/details/${params.id}/2.webp`} alt="" />
                                </article>

                                <article className="image">
                                    <img src={`/images/details/${params.id}/3.webp`} alt="" />
                                </article>
                            </>
                        }

                        { params.id !== '1' && params.id !== '7'
                            ? <>
                                <article className="image">
                                    <img src={`/images/details/${params.id}/4.webp`} alt="" />
                                </article>

                                <article className="image">
                                    <img src={`/images/details/${params.id}/5.webp`} alt="" />
                                </article>

                                <article className="image">
                                    <img src={`/images/details/${params.id}/6.webp`} alt="" />
                                </article>

                                <article className="image">
                                    <img src={`/images/details/${params.id}/7.webp`} alt="" />
                                </article>

                                <article className="image">
                                    <img src={`/images/details/${params.id}/8.webp`} alt="" />
                                </article>

                                <article className="image">
                                    <img src={`/images/details/${params.id}/9.webp`} alt="" />
                                </article>

                                { params.id === '2' || params.id === '4' || params.id === '5' || params.id === '6'
                                    ? 
                                    <>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/10.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/11.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/12.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/13.webp`} alt="" />
                                        </article>

                                    </>
                                    : <></>
                                }

                                { params.id === '4' || params.id === '5' 
                                    ? 
                                    <>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/14.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/15.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/16.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/17.webp`} alt="" />
                                        </article>

                                    </>
                                    : <></>
                                }

                                { params.id === '4' || params.id === '5'
                                    ? 
                                    <>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/18.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/19.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/20.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/21.webp`} alt="" />
                                        </article>
                                    </>
                                    : <></>
                                }

                                { params.id === '4'
                                    ? 
                                    <>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/22.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/23.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/24.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/25.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/26.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/27.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/28.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/29.webp`} alt="" />
                                        </article>
                                    </>
                                    : <></>
                                }

                                { params.id === '6' 
                                    ? 
                                    <>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/14.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/15.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/16.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/17.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/18.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/19.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/20.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/21.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/22.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/23.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/24.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/25.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/26.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/27.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/28.webp`} alt="" />
                                        </article>
                                        <article className="image">
                                            <img src={`/images/details/${params.id}/29.webp`} alt="" />
                                        </article>

                                    </>
                                    : <></>
                                }
                            </> 
                            : <></>
                        }

                    </section>
                </div>
            </section>

            { params.id !== '8' 
                ?
                    <section className="Images">
                    <div style={{ position: 'absolute', top: '50px' }}></div>
                    <h2>Elewacje i rzuty</h2>

                    <div className="container">
                        <article className="gallery-4">
                            <img src={`/images/details/${params.id}/rzut-1.webp`} alt="Galeria" />
                            <img src={`/images/details/${params.id}/rzut-2.webp`} alt="Galeria" />
                            <img src={`/images/details/${params.id}/rzut-3.webp`} alt="Galeria" />
                            <img src={`/images/details/${params.id}/rzut-4.webp`} alt="Galeria" />
                        </article>
                    </div>
                </section>
                : <></>
            } 

            <Contact />
            <Footer />
        </>
    );
}

export default Details;