import React from 'react';

import './index.scss';

const Technology = React.forwardRef((props, ref) => {
    return (
        <section className="Technology">
           <div ref={ref} style={{ position: 'absolute', top: '50px' }}></div>
           <h2>Technologia</h2>

           <div className="container">
                <article>
                    <h3>Konstrukcja</h3>
                    <span>Budownictwo szkieletowe liniowe i z prefabrykatów pozwala spełnić wysokie wymagania pod wszględem wizualnym zapewniając niskie koszty eksploatacji a duży komfort mieszkania.</span>
                    <span>Dzięki ścianom o niskim współczynniku przenikania ciepła i zastosowaniu wentylacji z odzyskiem ciepła w okresie zimowym oddychamy świeżym powietrzem czując ciepło, za to latem czujemy przyjemny chłód.</span>
                </article>

                <article>
                    <h3>Zwarta geometria</h3>
                    <span>Właściwy dobór materiałow izolacyjnych, membran wiatroizolacyjnych i przekroju konstrukcji szkieletowej, nie zapewni pełnych korzyści jakie ma do zaoferowania nowoczesny budynek. Istotna jest geometryczna zawartość bryły budynku (ilorazu powierzchni przegród zewnętrznych i objętości budynku). To od tego współczynnika zależy:</span>
                    <span>- bezwładność cieplna (wpływ temperatury zewnętrznej na materiały z których zbudowano dom)</span>
                    <span>- właściwa osłona termiczna (wyeliminowanie mostków termicznych)</span>
                    <span>- szczelność bryły uwzględniająca otwartość dyfuzyjną</span>
                </article>

                <article className="last">
                    <h3>Bilans cieplny budynku</h3>
                    <span>Po uzyskaniu optymalnego kompromisu pomiędzy kubaturą budynku, a jego powierzchnią grzewczą warto zapewnić niski współczynnik przenikania ciepła, który jednak sam w sobie nie sprawi, że dom będzie energooszczędny.</span>
                    <span>CZYNNIKI WPŁYWAJĄCE NA BILANS CIEPLNY BUDYNKU</span>
                    <span>- konstrukcja domu z materiałów o dużym cieple właściwym (ciepło właściwe drewna - drewna iglastego 2510 - stali 440)</span>
                    <span>- właściwy dobór izolacji termicznej tak, dla przykładu w Skandynawi od lat stosuje się izolacje o grubości 25cm</span>
                    <span>- wysoka jakość wykonania izolacjii termicznej i jej szczelości</span>
                    <span>- ciepły montaż stolarki otworowej zgodny z przeliczonym punktem rosy</span>
                    <span>- prosta bryła budynku, brak okien dachowych, duże okna wychodzące na stronę południową, pomieszczenia sanitarne i techniczne po stronie północnej </span>
                    <span>- rekuperator odpowiednio dobrany by krotność wymiany powietrza z odzyskiem cieplnym odpowiadała ilości zamieszkałych osób</span>
                    <span>- usytuowanie względem stron świata</span>
                    <span>- wentylacja z odzyskiem ciepła</span>
                </article>
           </div>
        </section>
    );
})

export default Technology;