import React from 'react';

import './index.scss';

const Informations = React.forwardRef((props, ref) => {
    return (
        <section className="Informations">
           <div ref={ref} style={{ position: 'absolute', top: '50px' }}></div>
           <h2>Informacje budowlane</h2>

           <div className="container">
                <article>
                    <h3>Rodzaje posadowień</h3>
                    <span>- Płyta fundamentowa beton B25 z włóknami polipropylenowymi z wodoszczelnym dodatkiem</span>
                    <span>- Płyta fundamentowa żelbetonowa zbrojona prętami</span>
                    <span>- Konstrukcja sporcza na bloczkach betonowych</span>
                    <span>- Fundament - bloczki murowane na ławie.</span>
                </article>

                <article>
                    <h3>Przygotowanie terenu</h3>
                    <span>Wymiana gruntu rodzimego polegająca na zdjęciu humusu oraz czarnoziemu i zastąpieniem ich pospółką, tyczenie rzędnych terenu.</span>
                </article>

                <article>
                    <h3>Rodzaj konstrukcji</h3>
                    <span>Konstrukcja szkieletowa liniowa z drewna C24, czterostronnie struganego i suszonego komorowo. Elementy prefabrykowane składane są na miejscu budowy.</span>
                </article>

                <article>
                    <h3>Dach</h3>
                    <span>Dach z blachy powlekanej w kształcie rąbka pojedynczego zamkniętego. Membrana wiatroizolacyjna i izolacja termiczna, paraizolacja. </span>
                </article>

                <article>
                    <h3>Ściany zewnętrzne</h3>
                    <span>Elewacja boczna i tylna z blachy powlekanej w kształcie rąbka pojedynczego zamkniętego.       Elewacja frontowa wykonana z deski elewacyjnej podwójnie impregnowanej.</span>
                </article>

                <article>
                    <h3>Rodzaje izolacji</h3>
                    <span>- wełna mineralna spełniająca podstawowe wymagania normy (EP &lt;= 95 kWh/m^2) lub o podwyższonym standardzie dla domu pasywnego (EP 15 &lt;= kWh/m^2)</span>
                    <span>- płyta PIR o grubości od 5 do 20 cm w zależności od wybranych  parametrach energetycznych</span>
                </article>
           </div>
        </section>
    );
})

export default Informations;