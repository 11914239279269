import React from 'react';

import './index.scss';

const Footer = () => {
    return (
        <header className="Footer">
            Materiały publikowane na stronie internetowej Ekobarn.pl podlegają ochronie prawnej, na podstawie ustawy o prawie autorskim.
            Prawnym właścicielem linii domów Allehouse Design jest firma Ekobarn. 
        </header>
    );
}

export default Footer;