import React from 'react';

import './index.scss';

const DayToDay = React.forwardRef((props, ref) => {
    return (
        <section className="DayToDay">
            <div ref={ref} style={{ position: 'absolute', top: '50px' }}></div>
            <h2>Z życia firmy</h2>

           <div className="container">
                <article className="gallery">
                    <img src="/images/day-to-day/1.webp" alt="Galeria" />
                    <img src="/images/day-to-day/2.webp" alt="Galeria" />
                    <img src="/images/day-to-day/3.webp" alt="Galeria" />
                    <img src="/images/day-to-day/4.webp" alt="Galeria" />

                    <img src="/images/day-to-day/5.webp" alt="Galeria" />
                    <img src="/images/day-to-day/6.webp" alt="Galeria" />
                    <img src="/images/day-to-day/7.webp" alt="Galeria" />
                    <img src="/images/day-to-day/8.webp" alt="Galeria" />

                    <img src="/images/day-to-day/9.webp" alt="Galeria" />
                    <img src="/images/day-to-day/10.webp" alt="Galeria" />
                    <img src="/images/day-to-day/11.webp" alt="Galeria" />
                    <img src="/images/day-to-day/12.webp" alt="Galeria" />
                </article>
           </div>
        </section>
    );
})

export default DayToDay;