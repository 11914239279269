import React from 'react';

import './index.scss';

const AboutUs = React.forwardRef((props, ref) => {
    return (
        <section className="AboutUs">
            <div ref={ref} style={{ position: 'absolute', top: '50px' }}></div>
            <h2>O nas</h2>

           <div className="container">
                <article>
                    <img src="/images/about_us/1.webp" alt="Spełniamy oczekiwania naszych klientów" />

                    <div className="text">
                        Spełniamy oczekiwania naszych klientów budując jak najlepszej jakości domy mieszkalne i rekreacyjne według powieżonych i własnych projektów.
                    </div>
                </article>

                <article>
                <img src="/images/about_us/2.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    
                    <div className="text">
                        Szczególną uwagę zwracamy na przestronność wnętrza
                        i energooszczędność zapewniając tym komfort mieszkania
                    </div>
                </article>

                <article>
                    <img src="/images/about_us/3.webp" alt="Spełniamy oczekiwania naszych klientów" />

                    <div className="text">
                        Domy wyposażone są w instalacje elektryczne, instalacje wodno-kanalizacyjne, opcjonalnie w instalację grzewczą.
                    </div>
                </article>

                <article style={{margin: '100px 0px'}}>
                    <div className="text">
                        Zakres naszej działalności obejmuje całą Polskę.
                        Budujemy domy szkieletowe z drewna kontrukcyjnego C24 kryte blachą w rąbek pojedynczy do rekreacji indywidualnej 
                        oraz domy mieszkalne energooszczędne wykonane w technologii pasywnej. <span className="gwarancja">Domy produkowane przez firmę EkoBarn objęte są pełną gwarancją.
Czas realizacji  produkcji domu wynosi od 1 do 3 miesięcy.</span>
                    </div>
                </article>

                <article className="gallery">
                    <img src="/images/about_us/4.webp" style={{ height: 'auto' }} alt="Galeria" />
                    <img src="/images/about_us/5.webp" style={{ height: 'auto' }} alt="Galeria" />
                    <img src="/images/about_us/6.webp" style={{ height: 'auto' }} alt="Galeria" />
                    <img src="/images/about_us/7.webp" style={{ height: 'auto' }} alt="Galeria" />

                    <img src="/images/about_us/8.webp" alt="Galeria" />
                    <img src="/images/about_us/9.webp" alt="Galeria" />
                    <img src="/images/about_us/10.webp" alt="Galeria" />
                    <img src="/images/about_us/11.webp" alt="Galeria" />

                    <img src="/images/about_us/12.webp" alt="Galeria" />
                    <img src="/images/about_us/13.webp" alt="Galeria" />
                    <img src="/images/about_us/14.webp" alt="Galeria" />
                    <img src="/images/about_us/15.webp" alt="Galeria" />

                    <img src="/images/about_us/16.webp" alt="Galeria" />
                    <img src="/images/about_us/17.webp" alt="Galeria" />
                    <img src="/images/about_us/18.webp" alt="Galeria" />
                    <img src="/images/about_us/19.webp" alt="Galeria" />
                </article>
                
           </div>
        </section>
    );
})

export default AboutUs;