import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faXmark } from '@fortawesome/free-solid-svg-icons';

import './index.scss';
import './App.scss';

import Homepage from './components/Homepage';
import AboutUs from './components/AboutUs';
import Technology from './components/Technology';
import Informations from './components/Informations';
import Offer from './components/Offer';
import Contact from './components/Contact';
import Footer from './components/Footer';
import DayToDay from './components/DayToDay';
// import OtherProjects from './components/OtherProjects';
import Video from './components/Video';

const App = () => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const aboutUsRef = React.createRef();
  const technologyRef = React.createRef();
  const informationsRef = React.createRef();
  const offerRef = React.createRef();
  const dayToDayRef = React.createRef();
//   const otherProjectsRef = React.createRef();
  const videoRef = React.createRef();
  const contactRef = React.createRef();

  const goToAboutUs = () => aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
  const goToTechnology = () => technologyRef.current.scrollIntoView({ behavior: 'smooth' });
  const goToInformations = () => informationsRef.current.scrollIntoView({ behavior: 'smooth' });
  const goToOffer = () => offerRef.current.scrollIntoView({ behavior: 'smooth' });
  const goToDayToDay = () => dayToDayRef.current.scrollIntoView({ behavior: 'smooth' });
//   const goToOtherProjects = () => otherProjectsRef.current.scrollIntoView({ behavior: 'smooth' });
  const goToVideo = () => videoRef.current.scrollIntoView({ behavior: 'smooth' });
  const goToContact = () => contactRef.current.scrollIntoView({ behavior: 'smooth' });

  const toggleMobileMenu = (event) => {
      setShowMobileMenu(!showMobileMenu);
  }

  return (
    <div className="App">
      <header className="Header">
          <div className="logo">
            <a href="/">
              <img src="/logo.png" alt="Allehouse Logo" />
              EkoBarn</a>
          </div>
          <nav className="navigation">
              <ul className="desktop-menu noselect">
                  <li onClick={goToAboutUs}>O Nas</li>
                  <li onClick={goToTechnology}>Technologia</li>
                  <li onClick={goToInformations}>Informacje budowlane</li>
                  <li onClick={goToOffer}>Oferta</li>
                  <li onClick={goToDayToDay}>Z życia firmy</li>
                  {/* <li onClick={goToOtherProjects}>Różne</li> */}
                  <li onClick={goToVideo}>Film</li>
                  <li onClick={goToContact}>Kontakt</li>
              </ul>

              <div className="mobile-menu-icon" onClick={toggleMobileMenu} style={{ color: '#aaa' }}>
                  { showMobileMenu ? <FontAwesomeIcon icon={faXmark} size="xl" /> : <FontAwesomeIcon icon={faEllipsisVertical} size="xl" /> }
              </div>
          </nav>
          <div>
              <div className={`mobile-menu ${ showMobileMenu ? '' : 'mobile-menu-hidden' }`}>
                  <ul>
                      <li onClick={goToAboutUs}>O Nas</li>
                      <li onClick={goToTechnology}>Technologia</li>
                      <li onClick={goToInformations}>Informacje budowlane</li>
                      <li onClick={goToOffer}>Oferta</li>
                      <li onClick={goToDayToDay}>Z życia firmy</li>
                      {/* <li onClick={goToOtherProjects}>Różne</li> */}
                      <li onClick={goToVideo}>Film</li>
                      <li onClick={goToContact}>Kontakt</li>
                  </ul>
              </div>
          </div>
      </header>
      
      <Homepage />
      <AboutUs ref={aboutUsRef} />
      <Technology ref={technologyRef} />
      <Informations ref={informationsRef} />
      <Offer ref={offerRef} />
      <DayToDay ref={dayToDayRef} />
      {/* <OtherProjects ref={otherProjectsRef} /> */}
      <Video ref={videoRef}/>
      <Contact ref={contactRef} />
      <Footer />
    </div>
  );
}

export default App;