import React from 'react';

import './index.scss';

const Offer = React.forwardRef((props, ref) => {
    const navigateTo = (id) => window.location.href = `/#/details/${ id }`;
    
    return (
        <section className="Offer">
           <div ref={ref} style={{ position: 'absolute', top: '50px' }}></div>
           <h2>Oferta</h2>

           <div className="container">
                <article onClick={ () => navigateTo(1) }>
                    <img src="/images/offer/1.webp" alt="Spełniamy oczekiwania naszych klientów" />
                    <span>Dom całoroczny piętrowy</span>
                </article>

                <article onClick={ () => navigateTo(7) }>
                    <img src="/images/offer/7.webp" alt="Spełniamy oczekiwania naszych klientów" />
                    <span>Dom całoroczny z antresolą</span>
                </article>

                <article onClick={ () => navigateTo(2) }>
                    <img src="/images/offer/2.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    <span>Dom parterowy</span>
                </article>

                <article onClick={ () => navigateTo(8) }>
                    <img src="/images/offer/8.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    <span>Ogród zimowy</span>
                </article>

                <article onClick={ () => navigateTo(3) }>
                    <img src="/images/offer/3.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    <span>Dom parterowy z fasadą przeszkloną</span>
                </article>

                <article onClick={ () => navigateTo(4) }>
                    <img src="/images/offer/4.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    <span>Dom parterowy z tarasem</span>
                </article>

                <article onClick={ () => navigateTo(5) }>
                    <img src="/images/offer/5.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    <span>Dom z antresolą</span>
                </article>

                <article onClick={ () => navigateTo(6) }>
                    <img src="/images/offer/6.webp" alt="Szczególną uwagę zwracamy na przestronność wnętrza" />
                    <span>Dom z przeszkloną antresolą</span>
                </article>
           </div>
        </section>
    );
})

export default Offer;