import React from 'react';

import './index.scss';

const Homepage = () => {
    return (
        <section className="Homepage">
             <article className="logo">
                <img src="/logo.png" alt="Allehouse Logo" />
            </article>

            <article className="primary-article">
                <h2>NOWOCZESNY DOM</h2>

                <div className="text">
                Dzięki zastosowaniu najnowszych trendów w architekturze połączyliśmy wytrzymałą na warunki atmosferyczne blachę elewacyjną w rąbek stojący z naturalnym drewnem. Odpowiednie przeszklenie pozwoliło dobrze nasłonecznić wnętrze. Wysokiej klasy drewno konstrukcyjne C-24 wypełniliśmy izolacją dającą komfort cieplny i akustyczny.
                </div>
            </article>

            <article className="secondary-article">
                <div className="text">
                Minimalistyczny taras pozwala zrelaksować się na świeżym powietrzu. Dom posadowiony może być na betonowej płycie fundamentowej lub drewnianej konstrukcji fundamentowej.
                Prawnym właścicielem linii domów Allehouse Design jest firma Ekobarn. Materiały publikowane na stronie internetowej Ekobarn.pl podlegają ochronie prawnej, na podstawie ustawy o prawie autorskim.
                </div>
            </article>
        </section>
    );
}

export default Homepage;