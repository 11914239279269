import React from 'react';

import './index.scss';

const Video = React.forwardRef((props, ref) => {
    return (
        <section className="Video">
           <div ref={ref} style={{ position: 'absolute', top: '50px' }}></div>
           <h2>Film instruktażowy</h2>

           <div className="container">
                <article>
                    <iframe className="player" src="https://www.youtube.com/embed/swXcl4NPI0o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </article>
           </div>
        </section>
    );
})

export default Video;